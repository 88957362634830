import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUserStore } from 'stores';
import cardMir from 'images/card_mir.png';
import cardVisa from 'images/card_visa.png';
import cardMC from 'images/card_mc.png';
import { ModalLogin } from 'modals';
import { useMemo } from 'react';
import i18n from 'i18next';

const Container = styled.div`
	padding: 40px;
	font-size: 16px;
	line-height: 21px;
	margin-top: auto;

	a {
		
	}

	@media (max-width: 1279px) {
		padding: 20px;
	}

	.whiteLine {
		margin: 20px 0;
		display: inline-block;

		@media (max-width: 1279px) {
			display: none;
		}
	}
`;


const Menu = styled.div`
	margin-bottom: 20px;
	gap: 15px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	@media (max-width: 1279px) {
		&:not(.mobile) {
			display: none;
		}

		&.mobile {
			display: flex;
			flex-direction: column;

			> div {
				display: flex;
				gap: 10px;
			}
		}
	}

	> a, div.link {
		display: inline;
		text-transform: uppercase;
		color: white !important;
		cursor: pointer;
	}
`;

const Creditionals = styled.div`
	font-family: ${props => props.theme.pt};
	text-align: center;
	margin-top: 20px;
	font-size: 16px;
	line-height: 21px;
	color: white !important;

	> div {
		font-size: inherit;
		font-weight: inherit;
		font-family: inherit;
		line-height: inherit;
		color: white;

		a {
			color: white !important;
			text-decoration: underline !important;
		}
	}

	@media (max-width: 1279px) {
		font-size: 14px;
		line-height: 18px;
		margin-top: 10px;

		&.noMargin {
			margin-top: 0;
		}
	}

	&:nth-child(2) {
		margin-bottom: 40px;
	}
`;

export const Footer = () => {
	const { t } = useTranslation();
	const lang = i18n.language;
	const {
		logout,
		isLogged,
	} = useUserStore();

	const year = useMemo(() => new Date().getFullYear(), []);

	return (
		<Container className='blueContainer'>
			<div>
				{!isLogged && 
					<Menu>
						<a href={`https://academservice.ru/${lang}-index.jsp`} target='_blank' rel="noreferrer" className='bold'>{t('АКАДЕМСЕРВИС')}</a>
						<a href='https://academonline.ru/add_hotel' target='_blank' rel="noreferrer">{t('ДОБАВИТЬ ОТЕЛЬ')}</a>
						<a href='https://academonline.ru/agent' target='_blank' rel="noreferrer">{t('СТАТЬ АГЕНТОМ')}</a>
					</Menu>
				}

				<Menu>
					<Link to="/">{t('Поиск')}</Link>
					<Link to="/countries">{t('Отели')}</Link>
					{isLogged && <Link to="/orders">{t('Заказы')}</Link>}
					<Link to="/about">{t('О нас')}</Link>
					<Link to="/contacts">{t('Контакты')}</Link>
					{!isLogged &&
						<ModalLogin>
							<div className='link'>{t('ВОЙТИ')}</div>
						</ModalLogin>
					}
					{isLogged && <div className='link' onClick={logout}>{t('ВЫЙТИ')}</div>}
				</Menu>

				<Menu>
					<Link to='/legaldocs'>{t('Правовые документы')}</Link>
					<img src={cardMir} width='63' height='30' />
					<img src={cardVisa} width='63' height='30' />
					<img src={cardMC} width='63' height='30' />
				</Menu>

				<Menu className='mobile'>
					<Link to='/legaldocs'>{t('Правовые документы')}</Link>
					<div>
						<img src={cardMir} width='63' height='30' />
						<img src={cardVisa} width='63' height='30' />
						<img src={cardMC} width='63' height='30' />
					</div>
				</Menu>

				<div className='whiteLine'/>

				<Creditionals>
					<div>{t('© «АКАДЕМ-ОНЛАЙН»')}, {year}. {t('Все права защищены')}</div>
					<div>{t('Разработано')} <a href="https://digitaltraveltech.ru/" target="_blank" rel="noreferrer">ООО &laquo;ЦТТ&raquo;</a></div>
				</Creditionals>
			</div>
		</Container>
	);
};